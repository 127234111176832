
import "./App.css";
import { useState } from "react";
import { create } from "ipfs-http-client";
import { Buffer } from "buffer";
import ReactPlayer from 'react-player';

// require('dotenv').config(); // Import and configure dotenv
// const IPFS = require('ipfs-http-client'); // Import the IPFS package

const Web3 = require("web3");
const web3 = new Web3(
  "https://mainnet.infura.io/v3/8db5dad32a3f490dac7aaf6cb2b23b82"
);

// const projectId = process.env.IPFS_PROJECT_ID;
// const projectSecret = process.env.IPFS_PROJECT_SECRET;

const projectId = "2DBM8M1VMImQ5vl4IQxYQzI8uKg";
const projectSecret = "f34bbedac4f01e7e79a9c99d04ba08cf";



const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  apiPath: "/api/v0",
  headers: {
    authorization: auth,
  },
});

function App() {
  const [fileUrl, updateFileUrl] = useState("");

  async function onChange(e) {
    const file = e.target.files[0];
    try {
      const added = await client.add(file);
      const url = `https://freeweb3.infura-ipfs.io/ipfs/${added.path}`;
      updateFileUrl(url);
      console.log(url);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  function updateFileName(input) {
    if (input.files.length > 0) {
        var fileName = input.files[0].name;
        var fileNameElement = input.parentNode.querySelector('.file-name');
        fileNameElement.textContent = fileName;
    }
}

  return (
    <div className="App">
      <div className="container">
      <div className="title">Welcome to IPFS:</div>
        <div className="subtitle">A Revolution in File Storage</div>
        <br />
        <div className="uploadtext">Upload a File to a Public Immutable Hash</div>
        <div className="more-info">Important! See instructions below.
        <br/>
<br/>
        </div>

        <label className="file-input">
            <input type="file" onChange={(e) => {onChange(e); updateFileName(e.target);}} />
            <span className="file-name">Browse...</span>
        </label>

        {fileUrl && (
          <img src={fileUrl} alt="content-hash-below" width="600px" />
        )}

        <div className="fileUrlBox">
          {fileUrl && (
            <p>{fileUrl}</p>
          )}
        <br/>
<br/>

        </div>

        
      </div>

    <br/>
      <br/>



      <a className="hyperlink" href="https://freeweb3.infura-ipfs.io/ipfs/QmaxD85spyPicFrrTe3wH6pJgLcBg8NMwnMqySv1peevRd">Additional Technical Information about IPFS</a>


    <br/>
      <br/>
    <div className="footer">
    <br/>
      <div className="paragraph">
        Introducing a new way to store and share your digital files securely in the <strong>IPFS (InterPlanetary File System)</strong>.
      </div>
      <br/>
      <br/>
      <div className="video-container">
     <ReactPlayer
      url="https://freeweb3.infura-ipfs.io/ipfs/QmbC2FboyLQwwdrhvs52cde2wr5JEmN1LbcickNk5TvwYv"
      controls
      width="90%"
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload'
          }
        }
      }}
    />
</div>

<br/>
      <div className="paragraph">
        As a valued member of the Web3 community, we're offering you access to <a href="https://upload.freeweb3.com" className="link">upload.freeweb3.com</a>, where you can upload your image or document files directly onto the IPFS network. IPFS provides a decentralized and censorship-resistant solution for storing and accessing files.
      </div>



      <div className="highlight">
        <div className="paragraph">
        <strong>Before you get started, we want to provide you with a brief reminder about the nature of IPFS-based content.</strong> Unlike traditional centralized storage systems, IPFS operates on a distributed network where content is hashed and publicly visible. While this offers many benefits, including enhanced security and accessibility, it's important to exercise caution when uploading sensitive or confidential information onto the IPFS network.
        </div>
        <div className="list">
          <div className="list-item-red">
            <strong>Encrypt your Private and Confidential Files:</strong> Prior to uploading any files onto IPFS, ensure that sensitive or confidential information is properly encrypted using strong encryption methods. This adds an extra layer of security and protects your data from unauthorized access.
          </div>
          <div className="list-item">
            <strong>Understand Public Visibility:</strong> Remember that content uploaded onto the IPFS network is publicly visible and accessible to anyone with the corresponding hash. Be mindful of the content you upload and avoid sharing anything that you wouldn't want to be publicly accessible.
          </div>
        </div>
      </div>

      {/* <div className="image-link-container">
  <a href="https://freeweb3.infura-ipfs.io/ipfs/QmYgPf8eeiySK33uiQbZLGWcrhgbGUej9jteNmqkN2vVdD">
    <img src="https://freeweb3.infura-ipfs.io/ipfs/QmYgPf8eeiySK33uiQbZLGWcrhgbGUej9jteNmqkN2vVdD" alt="IPFS-Example" />
  </a>
</div> */}

<div className="image-link-container">
  <a href="https://freeweb3.infura-ipfs.io/ipfs/QmS83B4gtkz4UdwtnniPPQj4Fo2NK65XAG7rAWjj1zcD9E">
    <img src="https://freeweb3.infura-ipfs.io/ipfs/QmS83B4gtkz4UdwtnniPPQj4Fo2NK65XAG7rAWjj1zcD9E" alt="IPFS-Example" />
  </a>
</div>

      <div className="paragraph">
        With these precautions in mind, feel free to explore the <a href="https://upload.freeweb3.com" className="link">upload.freeweb3.com</a> site and experience the power of IPFS firsthand. Upload your files with confidence, knowing that you're leveraging cutting-edge technology to safeguard your digital assets.
      </div>
      <div className="paragraph">
        Thank you for being part of our community, and we look forward to seeing you on the IPFS network.
      </div>
      <div>
      <a className="hyperlink" href="https://freeweb3.infura-ipfs.io/ipfs/QmaxD85spyPicFrrTe3wH6pJgLcBg8NMwnMqySv1peevRd">Additional Technical Information about IPFS</a>
      </div>
      <div>
      <a className="hyperlink" href="https://terms.freeweb3.com">Terms of Use and Privacy Policy</a>
      </div>
      </div>
      <br/>

    </div>
  );
}
export default App;
